<template>
  <div class="top-box">
    <span class="inline"></span>
    <span class="text">百科知识点</span>
  </div>
  <div class="box-shadow myForm">
    <div class="list-box">
      <div class="list" v-for="(item, index) in list" :key="index">
        <div>
          <div class="flex flex-align-items">
            <span>位置点：</span>
            <a-input
              :ref="`list${index}`"
              :disabled="item.disabled"
              v-model:value="item.unique_number"
              autocomplete="off"
              placeholder="请输入位置唯一点"
              :maxlength="30"
              :suffix="`${item.unique_number.length}/30`"
            ></a-input>
          </div>
          <div>
            <div class="contentBox box-shadow" v-for="(listItem,listIndex) in item.knowledges" :key="listIndex">
              <div>
                <div class="flex mt-10">
                  <span>标题：</span>
                  <a-textarea
                    class=""
                    :rows="5"
                    :ref="`list${index}`"
                    :disabled="!listItem.isEdit"
                    v-model:value="listItem.title"
                    autocomplete="off"
                    placeholder="请输入知识点标题"
                    :maxlength="500"
                    showCount/>
                </div>
                <div class="flex mt-10">
                  <span>内容：</span>
                  <a-textarea
                    :rows="5"
                    :ref="`list${index}`"
                    :disabled="!listItem.isEdit"
                    v-model:value="listItem.content"
                    autocomplete="off"
                    placeholder="请输入知识点内容"
                    :maxlength="500"
                    showCount/>
                </div>
              </div>
              <div class="flex-action">
                <a-button
                  class="btn"
                  v-if="listItem.isEdit"
                  type="primary"
                  @click="fetchPoint(item,listItem)"
                  >完成</a-button
                >
                <a-button
                  class="btn"
                  v-if="!listItem.isEdit"
                  type="primary"
                  @click="editPoint(listItem)"
                  >编辑</a-button
                >
                <a-button
                  class="btn"
                  type="danger"
                  v-if="!listItem.point_id  && item.knowledges.length > 1"
                  @click="handleDeleteList(item, listIndex)"
                  >删除</a-button
                >
                <a-button
                  class="btn"
                  type="danger"
                  v-if="listItem.point_id"
                  @click="handleDelete(listItem, listIndex)"
                  >删除</a-button
                >
              </div>
            </div>
          </div>
          <div class="add-div">
            <div class="addBtn" @click="addContentItem(item)">
              <PlusSquareOutlined class="icon-add" />
              <span>加入新的知识点内容</span>
            </div>
          </div>
          <div class="flex-action">
              <a-button
                class="btn"
                type="danger"
                v-if="!item.id && list.length > 1"
                @click="handleDeleteListItem(item, index)"
                >删除</a-button>
            </div>
        </div>
      </div>
    </div>
    <div class="add-div">
      <PlusSquareOutlined class="icon-add" @click="addItem" />
    </div>
  </div>
</template>

<script>
import { PlusSquareOutlined } from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
import { defineComponent } from 'vue'
import {
  addKnowledgepoint,
  initKnowledgepoint,
  editKnowledgepoint,
  deleteKnowledgepoint
} from '@/api/index'
import { showConfirm } from '@/hooks/common-hooks'
export default defineComponent({
  name: 'CreatePoints',
  props: ['id'],
  components: {
    PlusSquareOutlined
  },
  data() {
    return {
      isEdit: false,
      list: [
        {
          unique_number: '',
          knowledges: [{
            title: '',
            content: '',
            isEdit: true
          }]
        }
      ]
    }
  },
  mounted() {
    this.fetchInit()
  },
  methods: {
    // 初始化知识点
    fetchInit() {
      initKnowledgepoint({
        encyclopedia_id: this.id
      }).then(res => {
        if (res.data.length === 0) {
          this.isEdit = true
          this.list.splice(0, 1, {
            unique_number: '',
            knowledges: [{
              title: '',
              content: '',
              isEdit: true
            }]
          })
        } else {
          this.list = res.data.map(item => {
            item.disabled = true
            item.knowledges.forEach(element => {
              element.isEdit = false
            })
            return item
          })
        }
      })
    },
    checkEditItem(item) {
      return item.title.trim().length &&
            item.content.trim().length
    },
    // 检查是否可编辑
    checkEdit() {
      return this.list.every(
        item =>
          item.unique_number.trim().length &&
          (item.knowledges.every(list =>
            list.title.trim().length &&
            list.content.trim().length
          ))
      )
    },
    // 检查是否在编辑中
    checkAllEdit() {
      if (this.isEdit) {
        message.error('请先保存上次操作的数据')
        return false
      }
      return true
    },
    addItem() {
      if (!this.checkEdit()) {
        message.error('请先填写上一项')
        return false
      }
      if (!this.checkAllEdit()) return false
      this.addItemList()
    },
    addItemList() {
      this.list.push({
        unique_number: '',
        knowledges: [{
          title: '',
          content: '',
          isEdit: true
        }]
      })
      this.isEdit = true
    },
    checkAddItem(item) {
      return item.knowledges.every(
        item =>
          item.title.trim().length &&
          item.content.trim().length
      )
    },
    addContentItem(item) {
      if (!this.checkAddItem(item)) {
        message.error('请先填写上一项')
        return false
      }
      if (!this.checkAllEdit()) return false
      item.knowledges.push({
        title: '',
        content: '',
        isEdit: true
      })
    },
    // 获取添加数据
    getKnowledges(list) {
      return list.filter(item => {
        delete item.isEdit
        return !item.id
      })
    },
    // 添加数据
    fetchAdd (obj, item) {
      addKnowledgepoint({
        encyclopedia_id: this.id,
        unique_number: obj.unique_number,
        content: item.content,
        title: item.title
      }).then(() => {
        message.success('添加成功')
        this.fetchInit()
      })
    },
    // 编辑数据
    fetchEdit(obj, item, msg = '编辑成功') {
      editKnowledgepoint({
        point_id: item.point_id,
        encyclopedia_id: this.id,
        unique_number: obj.unique_number,
        content: item.content,
        title: item.title
      }).then(() => {
        message.success(msg)
        this.fetchInit()
      })
    },
    // 提交数据
    fetchPoint(obj, item) {
      if (!this.checkEdit()) {
        message.error('提交的数据不能有空项')
        return false
      }
      if (!Object.hasOwnProperty.call(item, 'point_id')) {
        this.fetchAdd(obj, item)
      } else {
        this.fetchEdit(obj, item)
      }
      this.isEdit = false
    },
    // 添加
    fetchPointAdd(item) {
      if (!this.checkEdit()) {
        message.error('提交的数据不能有空项')
        return false
      }
      this.fetchAdd(item)
      this.isEdit = false
    },
    // 编辑
    editPoint(item) {
      if (!this.checkAllEdit()) return false
      item.isEdit = true
      item.disabled = true
      this.isEdit = true
    },
    handleDeleteList(item, index) {
      item.knowledges.splice(index, 1)
      this.isEdit = false
    },
    handleDeleteListItem(item, index) {
      this.list.splice(index, 1)
      this.isEdit = false
    },
    // 删除项
    handleDelete(item, index) {
      if (!this.checkAllEdit()) return false
      showConfirm({
        content: '确定要删除该知识点吗?',
        callBack: () => {
          deleteKnowledgepoint({
            id: item.point_id
          }).then(() => {
            message.success('删除成功')
            this.fetchInit()
          })
        }
      })
    }
  }
})
</script>

<style lang="scss" scoped>
.flex-align-items{
  align-items: center;
}
.add-div {
  text-align: center;
  .addBtn{
    width:200px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    margin: 0 auto;
    color:#155fef;
    cursor: pointer;
  }
  &:hover {
    .icon-add {
      color: #155fef;
      cursor: pointer;
    }
  }
}
.myForm {
  padding: 20px;
}
.icon-add {
  font-size: 20px;
  margin-right:10px;
}
.list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
  & > div {
    width: 100%;
    padding: 20px;
    border: 1px solid #e8e8e8;
  }
  width: 100%;
  /deep/ .ant-input-textarea,
  /deep/ .ant-input-affix-wrapper {
    flex: 1;
  }
  .icon-handle {
    font-size: 28px;
    margin: 0 10px;
    color: #999;
    &:hover {
      color: #155fef;
    }
    cursor: pointer;
  }
}
.flex-action {
  flex: 0 0 200px;
  height: 32px;
  line-height: 32px;
  text-align: right;
  margin-top: 20px;
}
.btn {
  height: 32px;
  line-height: 32px;
  margin-top: -1px;
  margin-left: 10px;
}
.list-box {
  display: flex;
  flex-wrap: wrap;
}
.title {
  margin-left: 60px;
}
.contentBox{
  padding:10px 20px;
  margin:20px 0;
  padding-bottom: 20px;
  .flex-action{
    margin-top: 50px;
  }
}
</style>
